import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from '@material-ui/core';
import * as eventActions from '../../../state/event/eventAction';
import moment from 'moment';
import { API_BASE_URL, WHITE_LABEL_TEAM_ID } from '../../data/environment';
import { logourldata } from './logourldata';
import * as CSV from 'csv-string';
import csvtojson from 'csvtojson';
import { v4 as uuid } from 'uuid';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith',
  timezone: 'GTM-7'
};

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  inputLabel: {
    backgroundColor: 'white'
  },
  logoImg: {
    width: 120
  }
}));

const AddEventCode = ({ className, ...rest }) => {
  const [eventDetails, setEventDetails] = useState({});
  const [eventCode, setEventCode] = useState('');
  const [databaseName, setDatabaseName] = useState('');
  const [teamLogoData, setTeamLogoData] = useState(null);
  const [selectedLogoUrl, setSelectedLogoUrl] = useState('');

  // Parsing team's logo data from
  // csv string
  useEffect(() => {
    csvtojson()
      .fromString(logourldata)
      .then(data => {
        setTeamLogoData(data);
      });
  });

  const classes = useStyles();
  const dispatch = useDispatch();

  let textInput = useRef(null);

  const eventData = useSelector(state =>
    state.events.previewEvent[0] ? state.events.previewEvent[0] : null
  );

  const loadingEvent = useSelector(state =>
    state.apiRequestInProgress ? state.apiRequestInProgress : false
  );

  const handleLogoSelect = event => {
    setSelectedLogoUrl(event.target.value);
  };

  const getDatabase = async () => {
    const response = await fetch(
      `${API_BASE_URL}/admin/dsn/${WHITE_LABEL_TEAM_ID}`,
      {
        method: 'GET'
      }
    );
    const res = await response.json();
    setDatabaseName(res.dsn);
  };

  useEffect(() => {
    setEventDetails(eventData);
    getDatabase();
  }, [eventData]);

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          <Box alignItems="left" display="flex" flexDirection="column">
            <Typography color="textPrimary" gutterBottom variant="h3">
              Add new event
            </Typography>
            <TextField
              fullWidth
              helperText="Archtics event code. Ex: FS09"
              label="Event Code"
              name="firstName"
              onChange={e => setEventCode(e.target.value)}
              required
              variant="outlined"
              inputRef={textInput}
            />
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            onClick={() => {
              dispatch(eventActions.previewEvent(eventCode));
              textInput.current.value = '';
            }}
            disabled={loadingEvent}
          >
            {loadingEvent ? 'Loding...' : `LOOKUP EVENT IN ${databaseName}`}
          </Button>
        </CardActions>
      </Card>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
        style={{
          marginTop: 20
        }}
      >
        <CardContent>
          <Box alignItems="left" display="flex" flexDirection="column">
            <Typography color="textPrimary" gutterBottom variant="h3">
              Confirm Event Data
            </Typography>
            {eventDetails && eventDetails.event_date
              ? moment(eventDetails.event_date).format('MM/DD/YYYY')
              : null}
            <br />
            {eventDetails && eventDetails.event_description}
            <br />
            {eventDetails && eventDetails.event_time}

            <br />

            {selectedLogoUrl && (
              <Box mt={2} mb={1}>
                <img
                  className={classes.logoImg}
                  src={`http://ss-leagues.s3.us-east-2.amazonaws.com/${selectedLogoUrl.replace(
                    'ss-leagues/',
                    ''
                  )}`}
                />
              </Box>
            )}

            {eventDetails && (
              <Box mt={2}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel
                    className={classes.inputLabel}
                    htmlFor="team-logo-select"
                  >
                    Choose logo
                  </InputLabel>
                  <Select
                    native
                    value={selectedLogoUrl}
                    onChange={handleLogoSelect}
                    inputProps={{
                      name: 'team-logo-select',
                      id: 'team-logo-select'
                    }}
                  >
                    <option aria-label="None" value="" />
                    {teamLogoData &&
                      teamLogoData.map(team => {
                        return (
                          <option value={team.team_thumb_img}>
                            {team.team_name_full}
                          </option>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            onClick={() => {
              dispatch(eventActions.saveEvent(eventCode));
              setEventDetails({});
            }}
          >
            CONFIRM
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

AddEventCode.propTypes = {
  className: PropTypes.string
};

export default AddEventCode;
