export const logourldata = `team_id,"sport_league","team_name","team_city","team_name_full","team_name_no_space","team_thumb_img","team_stadium_svg"
0,NULL,NULL,NULL,NULL,NULL,NULL,NULL
1,"MLB","Diamondbacks","Arizona","Arizona Diamondbacks","ArizonaDiamondbacks","ss-leagues/MLB/arizona-diamondbacks.png",""
2,"MLB","Braves","Atlanta","Atlanta Braves","AtlantaBraves","ss-leagues/MLB/atlanta-braves.png",""
3,"MLB","Orioles","Baltimore","Baltimore Orioles","BaltimoreOrioles","ss-leagues/MLB/baltimore-orioles.png",""
4,"MLB","Red Sox","Boston","Boston Red Sox","BostonRedSox","ss-leagues/MLB/boston-red-sox.png",""
5,"MLB","Cubs","Chicago","Chicago Cubs","ChicagoCubs","ss-leagues/MLB/chicago-cubs.png",""
6,"MLB","White Sox","Chicago","Chicago White Sox","ChicagoWhiteSox","ss-leagues/MLB/chicago-white-sox.png",""
7,"MLB","Reds","Cincinnati","Cincinnati Reds","CincinnatiReds","ss-leagues/MLB/cincinnati-reds.png",""
8,"MLB","Rockies","Colorado","Colorado Rockies","ColoradoRockies","ss-leagues/MLB/colorado-rockies.png",""
9,"MLB","Indians","Cleveland","Cleveland Indians","ClevelandIndians","ss-leagues/MLB/cleveland-indians.png",""
10,"MLB","Tigers","Detroit","Detroit Tigers","DetroitTigers","ss-leagues/MLB/detroit-tigers.png",""
11,"MLB","Astros","Houston","Houston Astros","HoustonAstros","ss-leagues/MLB/houston-astros.png",""
12,"MLB","Royals","Kansas City","Kansas City Royals","KansasCityRoyals","ss-leagues/MLB/kansas-city-royals.png",""
13,"MLB","Anaheim","Los Angeles","Los Angeles Angels of Anaheim","LosAngelesAngelsofAnaheim","ss-leagues/MLB/los-angeles-angels-of-anaheim.png",""
14,"MLB","Dodgers","Los Angeles","Los Angeles Dodgers","LosAngelesDodgers","ss-leagues/MLB/los-angeles-dodgers.png",""
15,"MLB","Marlins","Miami","Miami Marlins","MiamiMarlins","ss-leagues/MLB/miami-marlins.png",""
16,"MLB","Brewers","Milwaukee","Milwaukee Brewers","MilwaukeeBrewers","ss-leagues/MLB/milwaukee-brewers.png",""
17,"MLB","Twins","Minnesota","Minnesota Twins","MinnesotaTwins","ss-leagues/MLB/minnesota-twins.png",""
18,"MLB","Mets","New York","New York Mets","NewYorkMets","ss-leagues/MLB/new-york-mets.png",""
19,"MLB","Yankees","New York","New York Yankees","NewYorkYankees","ss-leagues/MLB/new-york-yankees.png",""
20,"MLB","Athletics","Oakland","Oakland Athletics","OaklandAthletics","ss-leagues/MLB/oakland-athletics.png",""
21,"MLB","Phillies","Philadelphia","Philadelphia Phillies","PhiladelphiaPhillies","ss-leagues/MLB/philadelphia-phillies.png",""
22,"MLB","Pirates","Pittsburgh","Pittsburgh Pirates","PittsburghPirates","ss-leagues/MLB/pittsburgh-pirates.png",""
23,"MLB","Padres","San Diego","San Diego Padres","SanDiegoPadres","ss-leagues/MLB/san-diego-padres.png",""
24,"MLB","Giants","San Francisco","San Francisco Giants","SanFranciscoGiants","ss-leagues/MLB/san-francisco-giants.png",""
25,"MLB","Mariners","Seattle","Seattle Mariners","SeattleMariners","ss-leagues/MLB/seattle-mariners.png",""
26,"MLB","Cardinals","St Louis","St Louis Cardinals","StLouisCardinals","ss-leagues/MLB/st-louis-cardinals.png",""
27,"MLB","Rays","Tampa","Tampa Bay Rays","TampaBayRays","ss-leagues/MLB/tampa-bay-rays.png",""
28,"MLB","Rangers","Texas","Texas Rangers","TexasRangers","ss-leagues/MLB/texas-rangers.png",""
29,"MLB","Jays","Toronto","Toronto Blue Jays","TorontoBlueJays","ss-leagues/MLB/toronto-blue-jays.png",""
30,"MLB","Nationals","Washington","Washington Nationals","WashingtonNationals","ss-leagues/MLB/washington-nationals.png",""
31,"MLS","FC","Atlanta","Atlanta United FC","AtlantaUnitedFC","ss-leagues/MLS/atlanta-united-fc.png",""
32,"MLS","Fire","Chicago","Chicago Fire","ChicagoFire","ss-leagues/MLS/chicago-fire.png",""
33,"MLS","Rapids","Colorado","Colorado Rapids","ColoradoRapids","ss-leagues/MLS/colorado-rapids.png",""
34,"MLS","Crew","Columbus","Columbus Crew","ColumbusCrew","ss-leagues/MLS/columbus-crew.png",""
35,"MLS","United","DC","DC United","DCUnited","ss-leagues/MLS/dc-united.png",""
36,"MLS","Dallas","FC","FC Dallas","FCDallas","ss-leagues/MLS/fc-dallas.png",""
37,"MLS","Dynamo","Houston","Houston Dynamo","HoustonDynamo","ss-leagues/MLS/houston-dynamo.png",""
38,"MLS","Galaxy","LA","LA Galaxy","LAGalaxy","ss-leagues/MLS/la-galaxy.png",""
39,"MLS","FC","Minnesota","Minnesota United FC","MinnesotaUnitedFC","ss-leagues/MLS/minnesota-united-fc.png",""
40,"MLS","Impact","Montreal","Montreal Impact","MontrealImpact","ss-leagues/MLS/montreal-impact.png",""
41,"MLS","Revolution","New England","New England Revolution","NewEnglandRevolution","ss-leagues/MLS/new-england-revolution.png",""
42,"MLS","Bulls","New York","New York Red Bulls","NewYorkRedBulls","ss-leagues/MLS/new-york-red-bulls.png",""
43,"MLS","FC","New York","New York City FC","NewYorkCityFC","ss-leagues/MLS/new-york-city-fc.png",""
44,"MLS","SC","Orlando","Orlando City SC","OrlandoCitySC","ss-leagues/MLS/orlando-city-sc.png",""
45,"MLS","Union","Philadelphia","Philadelphia Union","PhiladelphiaUnion","ss-leagues/MLS/philadelphia-union.png",""
46,"MLS","Timbers","Portland","Portland Timbers","PortlandTimbers","ss-leagues/MLS/portland-timbers.png",""
47,"MLS","Salt Lake","Real","Real Salt Lake","RealSaltLake","ss-leagues/MLS/real-salt-lake.png",""
48,"MLS","Earthquakes","San Jose","San Jose Earthquakes","SanJoseEarthquakes","ss-leagues/MLS/san-jose-earthquakes.png",""
49,"MLS","Sounders FC","Seattle","Seattle Sounders FC","SeattleSoundersFC","ss-leagues/MLS/seattle-sounders-fc.png",""
50,"MLS","Sporting","Kansas City","Sporting Kansas City","SportingKansasCity","ss-leagues/MLS/sporting-kansas-city.png",""
51,"MLS","FC","Toronto","Toronto FC","TorontoFC","ss-leagues/MLS/toronto-fc.png",""
52,"MLS","Whitecaps FC","Vancouver","Vancouver Whitecaps FC","VancouverWhitecapsFC","ss-leagues/MLS/vancouver-whitecaps-fc.png",""
53,"NBA","Hawks","Atlanta","Atlanta Hawks","AtlantaHawks","ss-leagues/NBA/atlanta-hawks.png",""
54,"NBA","Celtics","Boston","Boston Celtics","BostonCeltics","ss-leagues/NBA/boston-celtics.png",""
55,"NBA","Nets","Brooklyn","Brooklyn Nets","BrooklynNets","ss-leagues/NBA/brooklyn-nets.png",""
56,"NBA","Hornets","Charlotte","Charlotte Hornets","CharlotteHornets","ss-leagues/NBA/charlotte-hornets.png",""
57,"NBA","Bulls","Chicago","Chicago Bulls","ChicagoBulls","ss-leagues/NBA/chicago-bulls.png",""
58,"NBA","Cavaliers","Cleveland","Cleveland Cavaliers","ClevelandCavaliers","ss-leagues/NBA/cleveland-cavaliers.png",""
59,"NBA","Mavericks","Dallas","Dallas Mavericks","DallasMavericks","ss-leagues/NBA/dallas-mavericks.png",""
60,"NBA","Nuggets","Denver","Denver Nuggets","DenverNuggets","ss-leagues/NBA/denver-nuggets.png",""
61,"NBA","Pistons","Detroit","Detroit Pistons","DetroitPistons","ss-leagues/NBA/detroit-pistons.png",""
62,"NBA","Warriors","Golden State","Golden State Warriors","GoldenStateWarriors","ss-leagues/NBA/golden-state-warriors.png",""
63,"NBA","Rockets","Houston","Houston Rockets","HoustonRockets","ss-leagues/NBA/houston-rockets.png",""
64,"NBA","Pacers","Indiana","Indiana Pacers","IndianaPacers","ss-leagues/NBA/indiana-pacers.png",""
65,"NBA","Clippers","Los Angeles","Los Angeles Clippers","LosAngelesClippers","ss-leagues/NBA/los-angeles-clippers.png",""
66,"NBA","Lakers","Los Angeles","Los Angeles Lakers","LosAngelesLakers","ss-leagues/NBA/los-angeles-lakers.png",""
67,"NBA","Grizzlies","Memphis","Memphis Grizzlies","MemphisGrizzlies","ss-leagues/NBA/memphis-grizzlies.png",""
68,"NBA","Heat","Miami","Miami Heat","MiamiHeat","ss-leagues/NBA/miami-heat.png",""
69,"NBA","Bucks","Milwaukee","Milwaukee Bucks","MilwaukeeBucks","ss-leagues/NBA/milwaukee-bucks.png",""
70,"NBA","Timberwolves","Minnesota","Minnesota Timberwolves","MinnesotaTimberwolves","ss-leagues/NBA/minnesota-timberwolves.png",""
71,"NBA","Pelicans","New Orleans","New Orleans Pelicans","NewOrleansPelicans","ss-leagues/NBA/new-orleans-pelicans.png",""
72,"NBA","Knicks","New York","New York Knicks","NewYorkKnicks","ss-leagues/NBA/new-york-knicks.png",""
73,"NBA","Thunder","Oklahoma City","Oklahoma City Thunder","OklahomaCityThunder","ss-leagues/NBA/oklahoma-city-thunder.png",""
74,"NBA","Magic","Orlando","Orlando Magic","OrlandoMagic","ss-leagues/NBA/orlando-magic.png",""
75,"NBA","76ers","Philadelphia","Philadelphia 76ers","Philadelphia76ers","ss-leagues/NBA/philadelphia-76ers.png",""
76,"NBA","Suns","Phoenix","Phoenix Suns","PhoenixSuns","ss-leagues/NBA/phoenix-suns.png",""
77,"NBA","Trail Blazers","Portland","Portland Trail Blazers","PortlandTrailBlazers","ss-leagues/NBA/portland-trail-blazers.png",""
78,"NBA","Kings","Sacramento","Sacramento Kings","SacramentoKings","ss-leagues/NBA/sacramento-kings.png",""
79,"NBA","Spurs","San Antonio","San Antonio Spurs","SanAntonioSpurs","ss-leagues/NBA/san-antonio-spurs.png",""
80,"NBA","Raptors","Toronto","Toronto Raptors","TorontoRaptors","ss-leagues/NBA/toronto-raptors.png",""
81,"NBA","Jazz","Utah","Utah Jazz","UtahJazz","ss-leagues/NBA/utah-jazz.png",""
82,"NBA","Wizards","Washington","Washington Wizards","WashingtonWizards","ss-leagues/NBA/washington-wizards.png",""
83,"NFL","Cardinals","Arizona","Arizona Cardinals","ArizonaCardinals","ss-leagues/NFL/arizona-cardinals.png",""
84,"NFL","Falcons","Atlanta","Atlanta Falcons","AtlantaFalcons","ss-leagues/NFL/atlanta-falcons.png",""
85,"NFL","Ravens","Baltimore","Baltimore Ravens","BaltimoreRavens","ss-leagues/NFL/baltimore-ravens.png",""
86,"NFL","Bills","Buffalo","Buffalo Bills","BuffaloBills","ss-leagues/NFL/buffalo-bills.png",""
87,"NFL","Panthers","Carolina","Carolina Panthers","CarolinaPanthers","ss-leagues/NFL/carolina-panthers.png",""
88,"NFL","Bengals","Cincinnati","Cincinnati Bengals","CincinnatiBengals","ss-leagues/NFL/cincinnati-bengals.png",""
89,"NFL","Browns","Cleveland","Cleveland Browns","ClevelandBrowns","ss-leagues/NFL/cleveland-browns.png",""
90,"NFL","Cowboys","Dallas","Dallas Cowboys","DallasCowboys","ss-leagues/NFL/dallas-cowboys.png",""
91,"NFL","Bears","Chicago","Chicago Bears","ChicagoBears","ss-leagues/NFL/chicago-bears.png",""
92,"NFL","Broncos","Denver","Denver Broncos","DenverBroncos","ss-leagues/NFL/denver-broncos.png",""
93,"NFL","Lions","Detroit","Detroit Lions","DetroitLions","ss-leagues/NFL/detroit-lions.png",""
94,"NFL","Packers","Green Bay","Green Bay Packers","GreenBayPackers","ss-leagues/NFL/green-bay-packers.png",""
95,"NFL","Texans","Houston","Houston Texans","HoustonTexans","ss-leagues/NFL/houston-texans.png",""
96,"NFL","Colts","Indianapolis","Indianapolis Colts","IndianapolisColts","ss-leagues/NFL/indianapolis-colts.png",""
97,"NFL","Jaguars","Jacksonville","Jacksonville Jaguars","JacksonvilleJaguars","ss-leagues/NFL/jacksonville-jaguars.png",""
98,"NFL","Chiefs","Kansas City","Kansas City Chiefs","KansasCityChiefs","ss-leagues/NFL/kansas-city-chiefs.png",""
99,"NFL","Rams","Los Angeles","Los Angeles Rams","LosAngelesRams","ss-leagues/NFL/los-angeles-rams.png",""
100,"NFL","Dolphins","Miami","Miami Dolphins","MiamiDolphins","ss-leagues/NFL/miami-dolphins.png",""
101,"NFL","Vikings","Minnesota","Minnesota Vikings","MinnesotaVikings","ss-leagues/NFL/minnesota-vikings.png",""
102,"NFL","Patriots","New England","New England Patriots","NewEnglandPatriots","ss-leagues/NFL/new-england-patriots.png",""
103,"NFL","Saints","New Orleans","New Orleans Saints","NewOrleansSaints","ss-leagues/NFL/new-orleans-saints.png",""
104,"NFL","Giants","New York","New York Giants","NewYorkGiants","ss-leagues/NFL/new-york-giants.png",""
105,"NFL","Jets","New York","New York Jets","NewYorkJets","ss-leagues/NFL/new-york-jets.png",""
106,"NFL","Raiders","Oakland","Oakland Raiders","OaklandRaiders","ss-leagues/NFL/oakland-raiders.png",""
107,"NFL","Eagles","Philadelphia","Philadelphia Eagles","PhiladelphiaEagles","ss-leagues/NFL/philadelphia-eagles.png",""
108,"NFL","Steelers","Pittsburgh","Pittsburgh Steelers","PittsburghSteelers","ss-leagues/NFL/pittsburgh-steelers.png",""
109,"NFL","Chargers","Los Angeles","Los Angeles Chargers","LosAngelesChargers","ss-leagues/NFL/los-angeles-chargers.png",""
110,"NFL","49ers","San Francisco","San Francisco 49ers","SanFrancisco49ers","ss-leagues/NFL/san-francisco-49ers.png",""
111,"NFL","Seahawks","Seattle","Seattle Seahawks","SeattleSeahawks","ss-leagues/NFL/seattle-seahawks.png",""
112,"NFL","Buccaneers","Tampa Bay","Tampa Bay Buccaneers","TampaBayBuccaneers","ss-leagues/NFL/tampa-bay-buccaneers.png",""
113,"NFL","Titans","Tennessee","Tennessee Titans","TennesseeTitans","ss-leagues/NFL/tennessee-titans.png",""
114,"NFL","Redskins","Washington","Washington Redskins","WashingtonRedskins","ss-leagues/NFL/washington-redskins.png",""
116,"NHL","Coyotes","Arizona","Arizona Coyotes","ArizonaCoyotes","ss-leagues/NHL/arizona-coyotes.png",""
117,"NHL","Bruins","Boston","Boston Bruins","BostonBruins","ss-leagues/NHL/boston-bruins.png",""
118,"NHL","Sabres","Buffalo","Buffalo Sabres","BuffaloSabres","ss-leagues/NHL/buffalo-sabres.png",""
119,"NHL","Flames","Calgary","Calgary Flames","CalgaryFlames","ss-leagues/NHL/calgary-flames.png",""
120,"NHL","Hurricanes","Carolina","Carolina Hurricanes","CarolinaHurricanes","ss-leagues/NHL/carolina-hurricanes.png",""
121,"NHL","Blackhawks","Chicago","Chicago Blackhawks","ChicagoBlackhawks","ss-leagues/NHL/chicago-blackhawks.png",""
122,"NHL","Avalanche","Colorado","Colorado Avalanche","ColoradoAvalanche","ss-leagues/NHL/colorado-avalanche.png",""
123,"NHL","Blue Jackets","Columbus","Columbus Blue Jackets","ColumbusBlueJackets","ss-leagues/NHL/columbus-blue-jackets.png",""
124,"NHL","Stars","Dallas","Dallas Stars","DallasStars","ss-leagues/NHL/dallas-stars.png",""
125,"NHL","Wings","Detroit","Detroit Red Wings","DetroitRedWings","ss-leagues/NHL/detroit-red-wings.png",""
126,"NHL","Oilers","Edmonton","Edmonton Oilers","EdmontonOilers","ss-leagues/NHL/edmonton-oilers.png",""
127,"NHL","Panthers","Florida","Florida Panthers","FloridaPanthers","ss-leagues/NHL/florida-panthers.png",""
128,"NHL","Kings","Los Angeles","Los Angeles Kings","LosAngelesKings","ss-leagues/NHL/los-angeles-kings.png",""
129,"NHL","Wild","Minnesota","Minnesota Wild","MinnesotaWild","ss-leagues/NHL/minnesota-wild.png",""
130,"NHL","Canadiens","Montreal","Montreal Canadiens","MontrealCanadiens","ss-leagues/NHL/montreal-canadiens.png",""
131,"NHL","Predators","Nashville","Nashville Predators","NashvillePredators","ss-leagues/NHL/nashville-predators.png",""
132,"NHL","Devils","New Jersey","New Jersey Devils","NewJerseyDevils","ss-leagues/NHL/new-jersey-devils.png",""
133,"NHL","Islanders","New York","New York Islanders","NewYorkIslanders","ss-leagues/NHL/new-york-islanders.png",""
134,"NHL","Rangers","New York","New York Rangers","NewYorkRangers","ss-leagues/NHL/new-york-rangers.png",""
135,"NHL","Senators","Ottawa","Ottawa Senators","OttawaSenators","ss-leagues/NHL/ottawa-senators.png",""
136,"NHL","Flyers","Philadelphia","Philadelphia Flyers","PhiladelphiaFlyers","ss-leagues/NHL/philadelphia-flyers.png",""
137,"NHL","Penguins","Pittsburgh","Pittsburgh Penguins","PittsburghPenguins","ss-leagues/NHL/pittsburgh-penguins.png",""
138,"NHL","Sharks","San Jose","San Jose Sharks","SanJoseSharks","ss-leagues/NHL/san-jose-sharks.png",""
139,"NHL","Blues","St Louis","St. Louis Blues","StLouisBlues","ss-leagues/NHL/st-louis-blues.png",""
140,"NHL","Lightning","Tampa","Tampa Bay Lightning","TampaBayLightning","ss-leagues/NHL/tampa-bay-lightning.png",""
141,"NHL","Maple Leafs","Toronto","Toronto Maple Leafs","TorontoMapleLeafs","ss-leagues/NHL/toronto-maple-leafs.png",""
142,"NHL","Canucks","Vancouver","Vancouver Canucks","VancouverCanucks","ss-leagues/NHL/vancouver-canucks.png",""
143,"NHL","Golden Knights","Vegas","Vegas Golden Knights","VegasGoldenKnights","ss-leagues/NHL/vegas-golden-knights.png",""
144,"NHL","Capitals","Washington","Washington Capitals","WashingtonCapitals","ss-leagues/NHL/washington-capitals.png",""
145,"NHL","Jets","Winnipeg","Winnipeg Jets","WinnipegJets","ss-leagues/NHL/winnipeg-jets.png",""
146,"NCAA","Ducks","Oregon","Oregon Ducks","OregonDucks","ss-leagues/NCAA/oregon-ducks.png",""
147,"NCAA","Sun Devils","Arizona State","Arizona State Sun Devils","ArizonaStateSunDevils","ss-leagues/NCAA/arizona-state-sun-devils.png",""
148,"NCAA","Wildcats","Arizona","Arizona Wildcats","ArizonaWildcats","ss-leagues/NCAA/arizona-wildcats.png",""
149,"NCAA","Falcons","Bowling Green","Bowling Green Falcons","BowlingGreenFalcons","ss-leagues/NCAA/bowling-green-falcons.png",""
150,"NCAA","Golden Bears","California","California Golden Bears","CaliforniaGoldenBears","ss-leagues/NCAA/california-golden-bears.png",""
151,"NCAA","Beavers","Oregon State","Oregon State Beavers","OregonStateBeavers","ss-leagues/NCAA/oregon-state-beavers.png",""
152,"NCAA","Vikings","Portland State","Portland State Vikings","PortlandStateVikings","ss-leagues/NCAA/portland-state-vikings.png",""
153,"NCAA","Spartans","San Jose State","San Jose State Spartans","SanJoseStateSpartans","ss-leagues/NCAA/san-jose-state-spartans.png",""
154,"NCAA","Bruins","UCLA","UCLA Bruins","UCLABruins","ss-leagues/NCAA/ucla-bruins.png",""
155,"NCAA","Utes","Utah","Utah Utes","UtahUtes","ss-leagues/NCAA/utah-utes.png",""
156,"NCAA","Huskies","Washington","Washington Huskies","WashingtonHuskies","ss-leagues/NCAA/washington-huskies.png",""
157,"NCAA","Cardinals","Stanford","Stanford Cardinals","StanfordCardinals","ss-leagues/NCAA/stanford-cardinals.png",""
158,"NCAA","Broncos","Boise State","Boise State Broncos ","BoiseStateBroncos","ss-leagues/NCAA/boise-state-broncos.png",""
159,"NCAA","Eagles","Eastern Washington","Eastern Washington Eagles","EasternWashingtonEagles","ss-leagues/NCAA/eastern-washington-eagles.png",""
160,"NCAA","Rattlers","Florida A&M","Florida A&M Rattlers","FloridaA&MRattlers","ss-leagues/NCAA/flordia-am-rattlers.jpg",""
161,"NCAA","Wolves","Western Oregon","Western Oregon Wolves","WesternOregonWolves","ss-leagues/NCAA/western-oregon-wolves.png",""
162,"NCAA","Trojans","USC","USC Trojans","USCTrojans","ss-leagues/NCAA/usc-trojans.png",""
163,"NCAA","Tigers","Texas Southern","Texas Southern Tigers","TexasSouthernTigers","ss-leagues/NCAA/texas-southern-tigers.png",""
164,"NCAA","Aztecs","San Diego","San Diego State Aztecs","SanDiegoStateAZtecs","ss-leagues/NCAA/san-diego-state-aztecs.png",""
165,"NCAA","Durangos","Nebraska-Omaha","Omaha Nebraska Durangos","OmahaNebraskaDurangos","ss-leagues/NCAA/omaha-nebraska-durangos.png",""
166,"NCAA","Pheonix","Green Bay","Green Bay Pheonix","GreenBayPheonix","ss-leagues/NCAA/green-bay-phoenix.png",""
167,"NCAA","Cougars","Washington State","Washington State Cougars","WashingtonStateCougars","ss-leagues/NCAA/washington-state-cougars.png",""
168,"NCAA","Wolf Pack","Nevada","Nevada Wolf Pack","NevadaWolfPack","ss-leagues/NCAA/nevada-wolf-pack.png",NULL
169,"NCAA","Grizzlies","Montana","Montana Grizzlies","MontanaGrizzlies","ss-leagues/NCAA/montana-state-grizzlies.png",NULL
170,"NCAA","Mavericks","UT Arlington","UT Arlington Mavericks","UTArlingtonMavericks","ss-leagues/NCAA/ut-arlington.png",NULL
171,"NCAA","Bulldogs","Fresno State","Fresno State Bulldogs","FresnoStateBulldogs","ss-leagues/NCAA/fresno-state.png",NULL
172,"NCAA","Hornets","Alabama State","Alabama State Hornets","AlabamaStateHornets","ss-leagues/NCAA/alabama-state.png",NULL
173,"NCAA","Rainbow Warriors","Hawaii","Hawaii Rainbow Warriors","HawaiiRainbowHornets","ss-leagues/NCAA/u-hawaii.png",NULL
174,"NCAA","Cougars","Houston","Houston Cougars","HoustonCougars","ss-leagues/NCAA/ut-houston.png",NULL
175,"NCAA","Buffaloes","Colorado","Colorado Buffaloes","ColoradoBuffaloes","ss-leagues/NCAA/colorado-buffaloes.png",NULL
176,"NBA","Loong Lions","Guangzhou","Guangzhou Loong Lions","GuangzhouLoongLions","ss-leagues/NBA/loong-lions.png",NULL
177,"MLS","FC","Cincinnati","FC Cincinnati","FCCincinnati","ss-leagues/MLS/fc-cincinnati.png",NULL
178,"MLS","SC","Inter Miami
","Inter Miami CF","InterMiamiCF","ss-leagues/MLS/inter-miami-sc.png",NULL
179,"MLS","SC","Nashville","Nashville SC","NashvilleSC","ss-leagues/MLS/nashville-sc.png",NULL
180,"NHL","Seattle","Kraken","Seattle Kraken","SeattleKraken","ss-leagues/NHL/kraken.png",NULL
181,"AHL","Tucson","Roadrunners","Tucson Roadrunners","TucsonRoadrunners","ss-leagues/AHL/roadrunners.png",NULL
`;
