export default {
  THEME: 'primary',
  logo: require('../../images/logo-roadrunners.png'),
  seatMap: require('../../images/roadrunners-map.png'),
  siteTitle: 'TUCSON ROADRUNNERS',
  teamName: 'Tucson Roadrunners',
  teamNameShort: 'Roadrunners',
  SUBDOMAIN: 'roadrunners',
  API_BASE_URL: 'https://roadrunners-prod.herokuapp.com',
  // API_BASE_URL: 'http://localhost:3001',
  IMAGE_BASE_URL: 'https://s3.us-east-2.amazonaws.com/',
  BACKGROUND_IMAGE_NAME: 'roadrunners-bg.jpg',
  BACKGROUND_IMAGE_FILE: require('../../images/roadrunners-bg.jpg'),
  WHITE_LABEL_TEAM_ID: 181,
  banner: {
    choose: 'EVENT SELECTION',
    desc: 'Redeem your account credit for upcoming events. '
  },
  faqs: [
    {
      question: 'How does the Roadrunners Credit Allocation Work?',
      answer:
        'The CCA allows fans to allocate game credits to specific events from the upcoming schedule. Simply log in to your Roadrunners Team Account and select games from your available account credit.'
    },
    {
      question: 'Do I have to select all my games now?',
      answer:
        'No. You have the opportunity to allocate credit throughout the season. However, please note that prices are subject to change.'
    },
    {
      question: 'Can I choose seats throughout the venue?',
      answer:
        'You can only apply credits to games for the seats that are on your account. If you would like to move seats please contact your team account representative.'
    },
    {
      question: 'Where are event tickets delivered?',
      answer:
        'Event tickets are delivered directly within your Roadrunners team account within 48 hours of selection.'
    },
    {
      question: 'Can I still forward and resell my tickets?',
      answer:
        'Yes. All account actions including forwarding and resale are available within your Roadrunners team account after games are selected.'
    }
  ]
};
