import * as types from './actionTypes';
import { eventApi } from '../../api/eventApi';
import { toast } from 'react-toastify';
import {
  beginApiCall,
  apiCallError,
  endApiCall
} from '../apiStatus/apiStatusActions';
import { apiReponseError } from '../../api/config/apiRequestError';
import Swal from 'sweetalert2';

export const loadAllEvents = () => {
  return dispatch => {
    dispatch(beginApiCall());
    return eventApi
      .getAllEvents()
      .then(response => {
        if (response.data) {
          const sortedEvents = response.data.events.sort((a, b) => new Date(a.date) - new Date(b.date))
          dispatch({
            type: types.GET_ALL_EVENTS,
            payload: sortedEvents
          });
        }
      })
      .catch(error => {
        dispatch(apiCallError());
        apiReponseError(error);
      })
      .finally(() => {
        dispatch(endApiCall());
      });
  };
};

export const previewEvent = eventCode => {
  return dispatch => {
    dispatch(beginApiCall());
    return eventApi
      .previewEvent(eventCode)
      .then(response => {
        if (response.data) {
          dispatch({
            type: types.PREVIEW_EVENT,
            payload: response.data.preview
          });
        }
      })
      .catch(error => {
        dispatch(apiCallError());
        if (error.response.status === 422) {
          toast.error('Please Enter Event Code');
        } else if (error.response.status === 500) {
          toast.error('Data not found');
        } else {
          apiReponseError(error);
        }
      })
      .finally(() => {
        dispatch(endApiCall());
      });
  };
};

export const saveEvent = eventCode => {
  return dispatch => {
    dispatch(beginApiCall());
    return eventApi
      .saveEvent(eventCode)
      .then(response => {
        if (response.data) {
          dispatch({
            type: types.SAVE_EVENT,
            payload: response.data.events
          });
        }
      })
      .catch(error => {
        dispatch(apiCallError());
        apiReponseError(error);
      })
      .finally(() => {
        dispatch(endApiCall());
      });
  };
};

export const loadSections = eventTicketData => {
  return dispatch => {
    dispatch(beginApiCall());
    dispatch({
      type: types.GET_SECTIONS,
      loading: true,
      payload: []
    });
    return eventApi
      .getSections(eventTicketData)
      .then(response => {
        if (response.data) {
          dispatch({
            type: types.GET_SECTIONS,
            payload: response.data.seatBlocks,
            loading: false,
          });
        }
      })
      .catch(error => {
        dispatch(apiCallError());
        apiReponseError(error);
      })
      .finally(() => {
        dispatch(endApiCall());
      });
  };
};

export const confirmTicket = confirmTicketData => {
  return eventApi
    .bookTicket(confirmTicketData)
    .then(response => {
      if (response.data) {
        return response.data;
      }
    })
    .catch(error => {
      window.mixpanel.track("Error reserving seat", {
        ...confirmTicketData
      })
      try {
        const msg = error.response.data.error.errorMessage || 'Failed to reserve seat block';
        apiReponseError(error);
        Swal.fire('Error', msg , 'error'); 
      } catch (error) {
        console.log(error)
        window.mixpanel.track("unexpected error", {
          error: error
        })
        Swal.fire('Error', "Seats not available. Select a different section or reduce your seat block size." , 'error'); 
      }
    });
};

export const deleteEvent = eventId => {
  return dispatch => {
    dispatch(beginApiCall());
    return eventApi
      .deleteEvent(eventId)
      .then(response => {
        if (response.data) {
          dispatch({
            type: types.GET_ALL_EVENTS,
            payload: response.data.events
          });
        }
      })
      .catch(error => {
        dispatch(apiCallError());
        apiReponseError(error);
      })
      .finally(() => {
        dispatch(endApiCall());
      });
  };
};
