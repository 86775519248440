import React, { useState } from 'react';
import one from '../images/1.png';
import two from '../images/2.png';
import three from '../images/3.png';

import teamAuth from '../images/team-auth.png';
import selectGames from '../images/choose-games.png';
import getTickets from '../images/get-tickets.png';

import banner from '../images/banner.png';
import bluebadge from '../images/blue-badge.png';
import Accordion from '../components/Accordion';
import {
  siteTitle,
  THEME,
  faqs,
  logo,
  SUBDOMAIN,
  WHITE_LABEL_TEAM_ID,
  API_BASE_URL,
  teamName,
  BACKGROUND_IMAGE_FILE as heroImage,
  development
} from '../views/data/environment';
import { Router, useHistory } from 'react-router-dom';

import axios from 'axios';

import './css/home.scss';
import Swal from 'sweetalert2';
import Axios from 'axios';
export default function Home() {
  const history = useHistory();

  const [teamAccountUrl, setTeamAccountUrl] = useState('');
  const [isEnabled, setIsEnabled] = useState(null);

  React.useEffect(() => {
    toggleIntercom('block');
    Axios.post(`${API_BASE_URL}/configuration/enabled`).then(res => {
      setIsEnabled(res.data.isPortalOpen)
    })
  });

  const toggleIntercom = mode => {
    let checkElement = setInterval(function () {
      let intercom = document.getElementsByClassName('intercom-launcher');
      if (intercom.length) {
        intercom[0].style.display = mode;
        clearInterval(checkElement);
      }
    }, 100);
  };

  React.useEffect(() => {
    // get team account URL
    axios.get(`${API_BASE_URL}/teamAuth/url/${WHITE_LABEL_TEAM_ID}`).then(
      response => {
        if(development == true){
          alert("DEV MODE!")
        }
        setTeamAccountUrl(response.data.url);
        const params = new URLSearchParams(history.location.search);
        const code = params.get('code');
        if (code) {
          const data = {
            teamId: WHITE_LABEL_TEAM_ID,
            authCode: code,
            redirectUri: `https://${SUBDOMAIN}.seasonshare.com/tmcallback/linkAccount`
          };
          axios.post(`${API_BASE_URL}/teamAuth/linkAccount`, data, {
            withCredentials: true
          }).then(
            response2 => {
              if (response2.data.accountNumber) {
                // remove the code to prevent error on back
                const queryParams = new URLSearchParams(history.location.search)
                if (queryParams.has('code')) {
                  queryParams.delete('code')
                  history.replace({
                    search: queryParams.toString(),
                  })
                }
                history.push({
                  pathname: `/products`,
                  state: {
                    accountId: response2.data.accountNumber
                  }
                })
              } else {
                Swal.fire(
                  'Foul!',
                  'Something strange happened. Please contact Live Fan Support.',
                  'error'
                );

                const slackURL =
                  'https://hooks.slack.com/services/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';
                const options = {
                  url: slackURL,
                  json: true,
                  body: {
                    text: JSON.stringify(response2.data)
                  }
                };
                axios.post(slackURL, options).then(
                  res => {
                    console.log(res);
                  },
                  err => console.log(err)
                );
              }
            },
            err => {
              if(development==true){
                return history.push({
                  pathname: `/products`,
                  state: {
                    accountId: "16934269"
                  }
                })
              }
              console.log(err.response);
              Swal.fire('Foul!', err.response.data.errorMessage, 'error');
              history.push('/');
            }
          );
        }
      },
      err => {
        console.log(err);
      }
    );
  }, []);

  const lookupAccount = () => {
    let replaced = teamAccountUrl.replace(
      'REDIRECT_URL',
      `https://${SUBDOMAIN}.seasonshare.com/tmcallback/linkAccount`
    ); // redirect back to where we came from, handle in params
    let finalUrl = replaced.replace('STATE', `UPLOAD-${WHITE_LABEL_TEAM_ID}`);
    window.location.href = `${finalUrl}`;
  };

  return (
    <div>
      <div className="header-cont-2">
        <h4>If you experience issues signing in to the system, log into your <a href="https://am.ticketmaster.com/coyotes/#/">Account Manager</a> to update your account details. </h4>
      </div>
      <div id={THEME} style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="text-col">
          <h1 className="header-text">{siteTitle} CREDIT BANK </h1>
          <p className="banner-paras">
            Redeem credits for the upcoming season.{' '}
          </p>
          {
            isEnabled==true &&
            <button
              className="btn main-btn"
              onClick={() => {
                let replaced = teamAccountUrl.replace(
                  'REDIRECT_URL',
                  `https://${SUBDOMAIN}.seasonshare.com/tmcallback/linkAccount`
                ); // redirect back to where we came from, handle in params
                let finalUrl = replaced.replace(
                  'STATE',
                  `UPLOAD-${WHITE_LABEL_TEAM_ID}`
                );
                window.location.href = `${finalUrl}`;
              }}
            >
              GET STARTED
          </button>
          }
          {
            isEnabled==false && 
            <h3>
              Credit portal is temporarily closed. You will be notified via email when we open back up. Thank you!
            </h3>
          }
        </div>
      </div>
      <div className="header-cont">
        <h3>HOW IT WORKS</h3>
      </div>
      <div className="flex-cont2">
        <div className="left">
          <div className="inner-right" style={{ textAlign: 'center' }}>
            <img src={one} className="one" alt="logo 2" />
            <b>
              <h3 className="ir-head no-margin-bottom">
                LOGIN TO YOUR TEAM ACCOUNT
              </h3>
            </b>
            <p className="ir-para">
              Sign into your {teamName} account using our secure Ticketmaster
              account portal.{' '}
            </p>
          </div>
        </div>
        <div className="right">
          <img src={teamAuth} className="sc" alt="logooo 1" />
        </div>
      </div>
      <div className="flex-cont2">
        <div className="left">
          <div className="inner-right">
            <img src={two} className="one" alt="logo 2" />
            <b>
              <h3 className="ir-head no-margin-bottom">SELECT YOUR GAMES</h3>
            </b>
            <p className="ir-para">
              Select the games you wish to attend based on the amount of credit
              you have available.{' '}
            </p>
          </div>
        </div>
        <div className="right">
          <img src={selectGames} className="sc" alt="logooo 1" />
        </div>
      </div>
      <div className="flex-cont2">
        <div className="left">
          <div className="inner-right">
            <img src={three} className="one" alt="logo 2" />
            <b>
              <h3 className="ir-head no-margin-bottom">GET YOUR TICKETS</h3>
            </b>
            <p className="ir-para">
              Sign into your {teamName} account using our secure Ticketmaster
              account portal.
            </p>
            {
              isEnabled && 
              <button
              style={{ cursor: 'pointer' }}
              onClick={() => {
                let replaced = teamAccountUrl.replace(
                  'REDIRECT_URL',
                  `https://${SUBDOMAIN}.seasonshare.com/tmcallback/linkAccount`
                ); // redirect back to where we came from, handle in params
                let finalUrl = replaced.replace(
                  'STATE',
                  `UPLOAD-${WHITE_LABEL_TEAM_ID}`
                );
                window.location.href = `${finalUrl}`;
              }}
              className="btn"
            >
              <b>CHECK YOUR CREDITS</b>
            </button>
            }
          </div>
        </div>
        <div className="right">
          <img src={getTickets} className="sc" alt="logooo 1" />
        </div>
      </div>
      <div className="flex-cont3">
        <h1 className="freq">HOW TO REDEEM CREDITS</h1>
      </div>
      <div className="flex-cont3">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/vgc_ZNrekXg"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className="flex-cont3">
        <h1 className="freq">FAQ</h1>
      </div>
      <div className="faq-container">
        {faqs.map((faq, index) => (
          <Accordion key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
      <div className="whole-footer">
        <div className="footer">
          <div className="badge-cont">
            <img src={bluebadge} className="footer-badge" alt="logooovhvkj1" />
            <p className="foot-texts">Built for fans</p>
          </div>
          <div className="links-cont">
            <b>
              <p className="foot-texts">Privacy Policy</p>
            </b>
            <b>
              <p className="foot-texts">About us</p>
            </b>
            <b>
              <p className="foot-texts">Terms of services</p>
            </b>
            <b>
              <p className="foot-texts">Press</p>
            </b>
          </div>
        </div>
        <div className="copyright-cont">
          <p className="bottom-texts">Copyright 2020 Season Share Inc.</p>
        </div>
        <button
          style={{ cursor: 'pointer' }}
          onClick={() => {
            let replaced = teamAccountUrl.replace(
              'REDIRECT_URL',
              `https://${SUBDOMAIN}.seasonshare.com/tmcallback/linkAccount`
            ); // redirect back to where we came from, handle in params
            let finalUrl = replaced.replace(
              'STATE',
              `UPLOAD-${WHITE_LABEL_TEAM_ID}`
            );
            window.location.href = `${finalUrl}`;
          }}
          className="btn"
        >
          <b>CHECK YOUR CREDITS</b>
        </button>
      </div>
    </div>
  );
}
